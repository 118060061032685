<template>
  <div class="lg:flex lg:space-x-10">
    <div class="w-full space-y-7">
      <div class="card p-4">
        <h1 class="lg:text-3xl text-2xl font-semibold mb-6">Chương trình {{ vovDaily.channel }} thu ngày {{ vovDaily.date }}/{{ vovDaily.month }}/{{ vovDaily.year }}</h1>

        <div v-if="vovDaily.programs && vovDaily.programs.data && vovDaily.programs.data.length">
          <div v-for="program in vovDaily.programs.data" :key="'program' + program.id">
            <router-link :to="{name: 'VovProgramDetail', params: {id: program.id}}">
              {{ program.title }} {{ program.start_time }}-{{ program.end_time }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../core/services/api.service";

export default {
  name: "VovDailyDetail",
  data() {
    return {
      id: null,
      vovDaily: {
        date: "",
        month: "",
        year: "",
        channel: ""
      }
    }
  },
  methods: {
    loadVovDailyDetail() {
      let query = `query($id: ID!) {
        vovDaily(id: $id) {
          id
          date
          month
          year
          channel
          programs(first: 100) {
            data {
              id
              title
              start_time
              end_time
            }
          }
        }
      }
      `;

      ApiService.graphql(query, {id: this.id})
          .then(({data}) => {
            if (data.data && data.data.vovDaily) {
              this.vovDaily = data.data.vovDaily;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    }
  },
  mounted() {
    this.id = this.$route.params.id;
    this.loadVovDailyDetail();
  },
  beforeRouteUpdate(to, from, next) {
    this.id = to.params.id;
    window.scrollTo(0, 0);
    next();
    this.loadVovDailyDetail();
  },
}
</script>
